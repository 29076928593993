<template>
  <div class="memberCentre">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">会员中心</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <div class="userInfo">
        <div class="avatarBox">
          <ImgDecypt class="avatar" :src="userInfo.portrait" round />
          <Vlogo :vid="userInfo.uid" class="vlogo" v-if="userInfo.merchantUser == 1" />
          <svg-icon class="certificaBorder" iconClass="certificaBorder" v-if="userInfo.merchantUser == 1" />
        </div>
        <div class="user">
          <div class="name" :class="{ vipName: userInfo.isVip && userInfo.vipLevel > 0 }">
            {{ userInfo.name }}
          </div>
          <div class="vipInfo" v-if="userInfo.isVip">有效期: {{ userInfo.vipExpireDate | time }}</div>
          <div class="noVipInfo" v-else>开通会员享更多权益</div>
        </div>
      </div>
      <div class="buyVipBox">
        <div class="tabBox">
          <div class="tab" @click="changeTabIndex(0)" :class="currentTab == 0 ? 'activeTab' : ''">
            <div class="icon"></div>
            <svg-icon class="icon" iconClass="buyVip" />
            <div class="title">VIP</div>
          </div>
          <div class="tab" @click="changeTabIndex(1)" :class="currentTab == 1 ? 'activeTab' : ''">
            <svg-icon class="icon" iconClass="buySvip" />
            <div class="title">SVIP</div>
          </div>
        </div>
        <div class="selectVipBox">
          <Vip @showPrivilege="showPrivilege" :vipList="vipList" v-if="vipList.length > 0 && currentTab == 0" />
          <Svip @showPrivilege="showPrivilege" :svipList="svipList" v-if="svipList.length > 0 && currentTab == 1" />
          <NoData class="nodata" v-if="(currentTab == 0 && vipList.length == 0) || (currentTab == 1 && svipList.length == 0)" />
        </div>
        <div class="vipPrivilege">
          <div class="title">VIP特权</div>
          <div class="privilegeBox">
            <div class="privilege" v-for="(item, index) in privileges" :key="item.privilegeName + index">
              <ImgDecypt class="icon" :src="item.img" round />
              <div class="name">{{ item.privilegeName }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="btnBox">
        <router-link tag="div" class="conCusService" to="/kefu">
          <svg-icon class="cusService" iconClass="cusService" />
          联系客服
        </router-link>
        <div class="openNow" @click="showPay(true)">立即开通</div>
      </div>
    </div>
    <RechargeBox ref="rechargeBox" :isRecharge="isRecharge" @closePay="showPay" />
  </div>
</template>

<script>
import Vlogo from '@/components/Vlogo';
import ImgDecypt from '@/components/ImgDecypt';
import NoData from '@/components/NoData';
import Vip from './Vip/index.vue';
import Svip from './Svip/index.vue';
import { queryVipList } from '@/api/mine';
import { mapGetters } from 'vuex';
import { Toast } from 'vant';
import RechargeBox from '@/components/RechargeBox';
export default {
  name: 'MemberCentre',
  components: {
    Vlogo,
    Vip,
    Svip,
    ImgDecypt,
    RechargeBox,
    NoData,
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    }),
  },
  data() {
    return {
      currentTab: 0, // tab选中的下标
      active: 0, // tab选中下标
      isRecharge: false, // 充值弹窗
      dcInfo: '', // 代充信息
      productID: '', // 商品ID
      // userInfo: {}, //用户信息
      graphicsId: '',
      graphicsTitle: '',
      vipList: [], // vip列表
      svipList: [], // svip列表
      privileges: [], // 特权列表
      rechargeList: [], // 充值列表
    };
  },
  created() {
    if (this.$route.query.t == 'vip') {
      this.active = 0;
    } else {
      this.active = 1;
    }
    this.queryVipList();
  },
  methods: {
    changeTabIndex(index) {
      this.currentTab = index;
    },
    // 查询会员卡列表
    async queryVipList() {
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(queryVipList);
        this.$store.commit('app/SET_LOADING', false);
        if (res && res.code == 200) {
          res.data.list.forEach((i) => {
            if (i.position === '会员卡') {
              let arr = [...i.list];
              let cid = this.$route.query.cid;
              if (cid) {
                arr.forEach((item, index) => {
                  if (item.productID == cid) {
                    let newArr = arr.splice(index);
                    this.vipList = [...newArr, ...arr];
                  }
                });
              } else {
                this.vipList = [...arr];
              }
            } else if (i.position === '超级会员卡') {
              let arr = [...i.list];
              let cid = this.$route.query.cid;
              if (cid) {
                arr.forEach((item, index) => {
                  if (item.productID == cid) {
                    let newArr = arr.splice(index);
                    this.svipList = [...newArr, ...arr];
                  }
                });
              } else {
                this.svipList = [...arr];
              }
            }
          });
          this.dcInfo = res.data.daichong;
        }
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('获取会员卡信息失败，请退出重试');
      }
    },
    showPrivilege(obj) {
      this.privileges = obj.privilege;
      obj.payData.dcInfo = this.dcInfo;
      this.rechargeList = obj.payData.rechargeList;
      this.$refs.rechargeBox.changItem(obj.payData);
    },
    showPay(flag) {
      if (flag && !this.rechargeList.length) return Toast('该金额暂无充值方式');
      this.isRecharge = flag;
    },
  },
  beforeRouteEnter(to, from, next) {
    //视频跳转过来
    if (from.name == 'CommunityVideo' || from.name == 'HorizontalVideo' || from.name == 'ShortVideo') {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = vm.$store.getters.currentVideoObj['id'];
        vm.$refs.rechargeBox.graphicsTitle = vm.$store.getters.currentVideoObj['title'];
      });
    } else if (from.name == 'ComRecommend' || from.name == 'Announcement') {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = 'banner';
      });
    } else if (from.name == 'TaskHall') {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = 'signIn';
      });
    } else if (from.name == 'PostDetails') {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = 'exceptional';
      });
    } else {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = 'user';
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.memberCentre {
  height: 100%;
  overflow-y: auto;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    border-bottom: 1px solid rgb(217, 217, 217);
    box-sizing: border-box;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }
  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .userInfo {
      display: flex;
      align-items: center;
      padding: 14px 13px;
      margin: 10px 0 12px;
      background-color: #fff;
      .avatarBox {
        position: relative;
        .avatar {
          height: 52px;
          width: 52px;
          background-color: #000;
          border-radius: 50%;
          margin-right: 6px;
        }
        .vlogo {
          width: 18px;
          height: 18px;
          position: absolute;
          right: 0;
          bottom: 0;
        }
        .certificaBorder {
          position: absolute;
          top: -10px;
          left: -3px;
          width: 100%;
          font-size: 25px;
          z-index: 1;
        }
      }
      .user {
        font-size: 14px;
        .name {
          font-size: 18px;
        }
        .vipName {
          color: #ff678f !important;
        }
        .vipInfo {
          color: #ff678f;
        }
        .noVipInfo {
          color: rgb(153, 153, 153);
        }
      }
    }
    .buyVipBox {
      .tabBox {
        height: 42px;
        background-color: rgb(239, 239, 239);
        display: flex;
        border-radius: 4px;
        .tab {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          .icon {
            height: 26px;
            width: 26px;
            margin-right: 6px;
          }
        }
        .activeTab {
          background-color: #fff;
        }
      }
      .selectVipBox {
        height: 190px;
        background-color: #fff;
        .nodata {
          height: 80px;
          /deep/ .icon {
            width: 160px;
          }
        }
      }
      .vipPrivilege {
        margin-top: 6px;
        margin-bottom: 92px;
        padding: 12px 32px 14px;
        background-color: #fff;
        border-radius: 4px;
        .title {
          font-size: 16px;
          font-weight: 800;
          margin-bottom: 10px;
        }
        .privilegeBox {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-row-gap: 18px;
          grid-column-gap: 24px;
          .privilege {
            width: 55px;
            height: 65px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .icon {
              height: 42px;
              width: 42px;
              border-radius: 50%;
              background-color: #000;
            }
            .name {
              font-size: 13px;
            }
          }
        }
      }
    }
    .btnBox {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      z-index: 10;
      bottom: 0px;
      left: 50%;
      transform: translate(-50%, 0);
      height: 82px;
      width: 100%;
      background-color: #fff;
      .conCusService {
        height: 40px;
        width: 170px;
        background-color: rgb(246, 246, 246);
        border: 2px solid rgb(102, 102, 102);
        box-sizing: border-box;
        border-radius: 6px;
        text-align: center;
        font-size: 14px;
        color: rgb(102, 102, 102);
        display: flex;
        justify-content: center;
        align-items: center;
        .cusService {
          height: 20px;
          width: 20px;
          margin-right: 10px;
        }
      }
      .openNow {
        height: 40px;
        width: 170px;
        background-color: rgb(255, 103, 143);
        border-radius: 6px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }
}
</style>
