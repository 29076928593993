<template>
  <div class="svip">
    <Swiper class="vipSwiper" ref="vipSwiper" :options="swiperOption">
      <SwiperSlide class="swiperSlide" v-for="item in svipList" :key="item.productID">
        <div class="vipBox" :class="{ activeVipBox: item.productID == selectedItem.productID }">
          <div class="vipLabel" v-if="item.tag && item.productID == selectedItem.productID">
            {{ item.tag }}
          </div>
          <div class="name">{{ item.productName }}</div>
          <div class="price">
            ¥
            <span>{{ item.discountedPrice / 10 }}</span>
          </div>
          <div class="orginalPrice">原价：¥{{ item.originalPrice / 10 }}</div>
          <div class="desc" v-if="item.actionDesc">
            {{ item.actionDesc }}
          </div>
          <div class="nodesc" v-else></div>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
export default {
  name: 'Vip',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: ['svipList'],
  data() {
    let _this = this;
    return {
      // 轮播配置
      swiperOption: {
        direction: 'horizontal', // 水平切换选项
        // loop: true, // 循环模式
        slidesPerView: 'auto', // 默认一个屏幕显示几张图
        // centeredSlides: true, // 每屏，居中显示
        // observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        // observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        freeMode: {
          enabled: true,
        },
        on: {
          // slideChangeTransitionEnd: () => {
          //   this.activeIndex = this.$refs.vipSwiper.$swiper ? this.$refs.vipSwiper.$swiper.realIndex : 0;
          //   this.selectedItem = this.svipList[this.activeIndex];
          //   this.changItem();
          //   if (this.$refs.vipSwiper.$swiper) {
          //     this.$refs.vipSwiper.$swiper.slideToLoop(this.$refs.vipSwiper.$swiper.realIndex, 0, false);
          //   }
          // },
          click: function () {
            _this.handleInvite(this);
          },
        },
      },
      activeIndex: 0, // 选中会员卡下标
      selectedItem: {}, // 选中
    };
  },
  created() {
    this.selectedItem = this.svipList[0];
    this.changItem();
  },
  methods: {
    // 点击会员卡
    handleInvite(vm) {
      this.activeIndex = this.$refs.vipSwiper.$swiper ? this.$refs.vipSwiper.$swiper.clickedIndex : 0;
      this.selectedItem = this.svipList[this.activeIndex];
      this.changItem();
      // this.$refs.vipSwiper.$swiper.slideTo(vm.clickedIndex);
      // let index = vm.clickedIndex - vm.activeIndex + vm.realIndex;
      // if (index === this.svipList.length) {
      //   index = 0;
      // } else if (index === this.svipList.length + 1) {
      //   index = 1;
      // } else if (index === this.svipList.length + 2) {
      //   index = 2;
      // }
    },
    // 选中会员卡
    changItem() {
      let payData = {
        rechargeList: this.selectedItem.rchgType, // 支付方式列表
        // dcInfo: this.dcInfo, // 代充信息
        popType: 'vip', // 弹窗类型
        productType: this.selectedItem.productType, // 商品类型
        productID: this.selectedItem.productID, // 商品ID
        dcType: 1, // 代充商品类型
        buyType: 4, // 商品类型  1-是金币 2-是游戏币 3-是果币 4-是商品
        discountedPrice: this.selectedItem.discountedPrice / 10, // 充值金额
        isAmountPay: this.selectedItem.isAmountPay, // 是否支持金币兑换
      };
      this.$emit('showPrivilege', {
        privilege: this.selectedItem.privilege,
        payData: payData,
      }); // 给父组件特权
    },
  },
};
</script>
<style lang="scss" scoped>
.svip {
  height: 100%;
  overflow-x: auto;
  display: flex;
  align-items: center;
  padding: 0 8px;
  .vipSwiper {
    height: 180px;
    .swiperSlide {
      width: 121px;
      margin: 0 6px;
      .vipBox {
        height: 150px;
        width: 100%;
        position: relative;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 2.5px solid rgb(242, 242, 242);
        border-radius: 10px;
        margin-top: 15px;
        .vipLabel {
          font-size: 12px;
          color: white;
          padding: 3px 6px;
          background-color: rgb(255, 103, 143);
          border-radius: 15px 15px 15px 0;
          position: absolute;
          left: -2px;
          top: -13px;
          z-index: 10;
          min-width: 30px;
          text-align: center;
        }
        .name {
          font-size: 14px;
          color: rgb(120, 115, 105);
        }
        .price {
          display: flex;
          align-items: center;
          margin-top: 10px;
          span {
            font-size: 24px;
            font-weight: 800;
            margin-left: 3px;
          }
        }
        .orginalPrice {
          color: rgb(167, 166, 167);
        }
        .desc {
          color: rgb(102, 102, 102);
          font-size: 12px;
          padding: 2px 8px;
          background-color: rgb(241, 241, 241);
          border-radius: 4px;
          margin-top: 10px;
        }
        .nodesc {
          height: 30px;
        }
      }
      .activeVipBox {
        border: 2.5px solid rgb(255, 103, 143);
        background-color: rgb(255, 238, 242);
        .price {
          color: rgb(255, 103, 143);
        }
        .desc {
          color: #fff;
          background-color: rgb(255, 103, 143);
        }
      }
    }
  }
}
</style>
